/* eslint-disable react/style-prop-object */
import {
  Button,
  HStack,
  Heading,
  Image,
  Stack,
  chakra,
} from '@chakra-ui/react';
import './App.css';
import { useEffect, useRef, useState } from 'react';

function App() {
  const [counterX, setCounterX] = useState(0);
  const [counterC, setCounterC] = useState(0);
  const [counterZ, setCounterZ] = useState(0);
  const ref = useRef();
  const handleKey = (e) => {
    if ('x' === e.key) {
      setCounterX((prev) => prev + 1);
    }
    if ('z' === e.key) {
      setCounterZ((prev) => prev + 1);
    }
    if ('c' === e.key) {
      setCounterC((prev) => prev + 1);
    }
  };

  const data = [
    {
      id: 1,
      brand: '/images/audi.png',
      gift: '/images/rose.png',
      counter: counterZ,
      className: 'animation a',
    },
    {
      id: 2,
      brand: '/images/bmw.png',
      gift: '/images/tt.png',
      counter: counterX,
      className: 'animation b',
    },
    {
      id: 3,
      brand: '/images/mercedes.png',
      gift: '/images/ice.png',
      counter: counterC,
      className: 'animation c',
    },
  ];
  useEffect(() => {}, []);
  return (
    <Stack
      w={'full'}
      h={'100vh'}
      bg={'linear-gradient(134deg, rgba(0,0,0,1) 30%, rgba(98,98,98,1) 160%)'}
      ref={ref}
      position={'relative'}
    >
      {' '}
      <chakra.video
        w={'full'}
        position={'absolute'}
        h={'100vh'}
        objectFit={'cover'}
        src={'/images/video.mp4'}
        type="video/mp4"
        autoPlay
        loop
        muted
        controls={false}
      ></chakra.video>
      <Stack
        w={'full'}
        h={'100vh'}
        zIndex={2}
        position={'absolute'}
        bg={'rgba(0,0,0,0.5)'}
      ></Stack>
      <HStack
        color={'#f3f3f3'}
        w={'full'}
        h={'100vh'}
        justifyContent={'space-evenly'}
        alignItems={'center'}
        zIndex={1000}
      >
        {data.map((item) => (
          <Stack alignItems={'center'} gap={'3rem'} key={item.id}>
            <Image
              objectFit={'contain'}
              w={'300px'}
              h={'200px'}
              src={item.brand}
              className={item.className}
              alt={'Tiktok counter'}
            />
            <Button
              _focus={'none'}
              _active={'none'}
              _hover={'none'}
              _focusVisible={'none'}
              variant={'ghost'}
              onKeyDown={(e) => handleKey(e)}
            >
              <Image w={'100px'} src={item.gift} />
            </Button>
            <Heading as={'h2'} fontSize={'4rem'}>
              {item.counter}
            </Heading>
          </Stack>
        ))}
      </HStack>
    </Stack>
  );
}

export default App;
